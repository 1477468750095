import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IUser} from "../user/user.type";
import {isJsonString} from "../../../functions/global";

const initialState: any = {users: [], value: {category: [], child_category: [], partner: false } , search: ''}

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    filterUser: (state, action: PayloadAction<{ users: any, value: any, search: string }>) => {
      const d = action.payload
      const keys = Object.keys(d.value)
      if (d.users) {
        const checkFilter = () => {
          let users = d.users.filter((user: any) => {
            return d.value[keys[0]].filter((item: any) => {
              if (isJsonString(user[keys[0]])){
                return JSON.parse(user[keys[0]].toLowerCase()).filter((c:any) => {
                  if (c === item.value){
                    return user
                  }
                })[0]
              }
              if ((user[keys[0]].toLowerCase() === item.value)) {
                return user
              }
            })[0]
          })

          users = users.length > 0 ? users : d.users

          const filter2 = users.filter((user: any) => {
            return d.value[keys[1]].filter((item: any) => {

              if (isJsonString(user[keys[1]])) {
                return JSON.parse(user[keys[1]]).filter((e: any) => {
                  if (e.name && item.value) {
                    if (e.name.toLowerCase() === item.value.toLowerCase()) {
                      return user
                    }
                  }
                })[0]
              }
            })[0]
          })


          users = filter2.length > 0 ? filter2 : users

          users = users.filter((user: any) => {
            if (d.value.partner){
              if (user[keys[2]] === 1){
                return user
              }
            }else{
              return user
            }
          })
          return users
        }

        let users = checkFilter()

        users = users.filter((user: IUser) => {
          if (d.search.length > 0){
            const reffer = Object.keys(user).indexOf('reffer')
            const values = Object.values(user).filter((val, i) => { if (i !== reffer) return val})
            if (values.toString().toLowerCase().includes(d.search.toLowerCase())){
              return user
            }
          }else{
            return user
          }
        })

        if (d.value[keys[0]].length > 0 || d.value[keys[1]].length > 0 || d.value[keys[2]] || d.search.length > 0){
          return users.length > 0 ? {users: users, value: d.value, search: d.search} : {users: false, value: d.value, search: d.search}
        }else{
          return {users: d.users, value: d.value, search: d.search}
        }
      }else{
        return {users: false, value: d.value, search: d.search}
      }
    },
  },
})

export const filter = filterSlice.reducer
export const filterActions = filterSlice.actions