import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: any = []
let arr:any = []
export const categoryChangeSlice = createSlice({
  name: 'categoryChange',
  initialState,
  reducers: {
    categoryChange: (state, action: PayloadAction<any>) => {
      const a = action.payload
      arr = [...arr, a]
      function byField(field:string) {
        return (a:any, b:any) => a[field] > b[field] ? 1 : -1
      }
      arr = arr.sort(byField('index'))
      arr = [...arr].reduce((a, c) => (a.map((e:any) => e.index).includes(c.index) || a.push(c), a), [])
      return arr
    },
    categoryRemove: (state, action: PayloadAction<any>) => {
      const index = action.payload
      arr = arr.filter((item:any) => item.index !== index)
      return arr
    },
    categoryDrop: (state) => {
      arr = []
      return arr
    },
  },
})

export const categoryChange = categoryChangeSlice.reducer
export const categoryChangeActions = categoryChangeSlice.actions