import styles from './UserList.module.scss'
import {IUser} from "../../app/store/user/user.type"
import {FC} from "react"
import {useTypedSelector} from "../../app/hooks/useTypedSelector"
import {NotFound} from "../NotFound"
import {User} from "../User"

export const UserList: FC<{ users: IUser[] }> = (props) => {
  const { filter } = useTypedSelector(state => state)
  const users = filter.users.length > 0 ? filter.users : props.users
  if (!users || users.length === 0 || !filter.users) return <NotFound/>

  return(
    <>
      <div className={styles.users_list}>
        {
          users.map((user: IUser) => {
            return(
              <User key={user.id} user={user}></User>
            )
          })
        }
      </div>
    </>
  )
}