import {configureStore} from "@reduxjs/toolkit"
import {api} from "./store/user/user.api"
import {filter} from "./store/filter/filter.slice"
import {adminChange} from "./store/form/adminChange.slice"
import {categoryChange} from "./store/form/categoryChange.slice"

export const store = configureStore({
  reducer: {[api.reducerPath]: api.reducer, filter, adminChange, categoryChange},
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(api.middleware)
})

export type TypeRootState = ReturnType<typeof store.getState>