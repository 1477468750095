import {FC, useEffect, useState } from "react"
import { useActions } from "../../app/hooks/useActions"
import { IUser } from "../../app/store/user/user.type"
import { isJsonString, ucFirst } from "../../functions/global"
import styles from "./CategoryInputs.module.scss"
import Select from "react-select/creatable"
import TextareaAutosize from 'react-textarea-autosize'

export const CategoryInputs:FC<{
  users:IUser[], index:number, category: string,
  childCategory: any, business: string, site: string, length: number}> = (props) => {
  const index = props.index
  const { categoryChange, categoryRemove } = useActions()
  const [category, setCategory] = useState({value:props.category, label: ucFirst(props.category)})
  const [childCategory, setChildCategory] = useState(props.childCategory ? props.childCategory : {name:'', parent:''})
  const [business, setBusiness] = useState(props.business)
  const [site, setSite] = useState(props.site)


  const getCategory = () => {
    let arr:any = []
    let category:any = []
    if (props.users) {
      props.users.map((item) => {
        JSON.parse(item.category).map((item:string,index:number)=>{
          if (item !== null) {
            if (!arr.includes(item.trim())) {
              arr.push(item.trim())
              category.push({value: item.trim(), label: ucFirst(item.trim())})
            }
          }
        })
      })
    }
    category.sort((a:any, b:any) => a.value.localeCompare(b.value))
    return category
  }
  const getChildCategory = (parentCat:string) => {
    let category:any = []
    let childCategory:any = []
    if (props.users) {
      props.users.map((e) => {
        if (isJsonString(e.child_category)) {
          const child = JSON.parse(e.child_category.toLowerCase())
            child.map(({parent, name}:any)=>{
              if (parent === parentCat){
                category.push({value: name, label: ucFirst(name)})
              }
            })
          const table:any = {}
          childCategory = category.filter(({value}:any)=>(!table[value] && (table[value] = 1)))
        }
      })
    }
    childCategory.sort((a:any, b:any) => a.value.localeCompare(b.value))
    return childCategory
  }

  useEffect(()=>{
    categoryChange({category, site, index, childCategory, business})
  },[category, site, childCategory, business])


  return (
    <div className={styles.inputs}>
      <div className={styles.label}>Сфера бизнеса</div>
      <Select
        options={getCategory()}
        classNamePrefix="react-select"
        className="react-select"
        placeholder="Выбрать"
        formatCreateLabel={props => props.replace(/ /g,'').length > 0 ? `Создать: ${props}` : ''}
        onCreateOption={value => value.replace(/ /g,'').length > 0 ? setCategory({value: value, label: value}) : '' }
        value={category}
        onChange={(e:any)=>{
          setCategory(e)
          setChildCategory({...childCategory, parent: e.value})
        }}
      />
      <div className={styles.label}>Подсфера</div>
      <Select
        options={getChildCategory(category.value)}
        classNamePrefix="react-select"
        className="react-select"
        placeholder="Выбрать"
        formatCreateLabel={props => props.replace(/ /g,'').length > 0 ? `Создать: ${props}` : ''}
        onCreateOption={value => value.replace(/ /g,'').length > 0 ? setChildCategory({name: value, parent: category.value}) : '' }
        value={{
          value: childCategory.name ? childCategory.name.toLowerCase() : '',
          label: ucFirst(childCategory.name)
        }}
        onChange={(e:any)=>{
          setChildCategory({name: e.value, parent: category.value})
        }}
      />
      <div className={styles.label}>Название компании</div>
      <TextareaAutosize
        minRows={1}
        maxRows={20}
        className={styles.textarea}
        defaultValue={business}
        placeholder="Название компании"
        onChange={
          e => setBusiness(e.target.value)
        }
      />
      <div className={styles.label}>Сайт компании (если имеется)</div>
      <TextareaAutosize
        minRows={1}
        maxRows={20}
        className={styles.textarea}
        defaultValue={site}
        placeholder="https://site.ru"
        onChange={
          e => setSite(e.target.value)
        }
      />
      <div className={styles.buttons}>
        {props.length-1 === index ?
          <div className={styles.button}
               onClick={() => {
                 categoryChange({category: '', site: '', index: index + 1, childCategory: ''})
               }}>Добавить бизнес</div> : ''}
        {props.length-1 === index && index != 0
          ? <div
            className={`${styles.button} ${styles.remove}`}
            onClick={() => {
              categoryRemove(index)
            }}>
            Удалить бизнес
          </div>
          : ''}
      </div>
    </div>
  )
}