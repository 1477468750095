import styles from './Filters.module.scss'
import '../../select.scss'
import { MultiSelect } from "react-multi-select-component"
import {ReactComponent as SearchIcon} from "../../assets/icons/search.svg"
import {ReactComponent as FiltersIcon} from "../../assets/icons/filters.svg"
import {ReactComponent as CloseIcon} from "../../assets/icons/close.svg"
import {useActions} from "../../app/hooks/useActions"
import React, {FC, useState} from "react"
import {IUser} from "../../app/store/user/user.type";
import {SwipeableList, SwipeableListItem} from "@sandstreamdev/react-swipeable-list";
import {isJsonString, ucFirst} from "../../functions/global"
import { useTypedSelector } from '../../app/hooks/useTypedSelector'

export const Filters:FC<{users:IUser[]}> = (props) => {
  const users = props.users ? props.users : []
  const { filterUser } = useActions()
  const { filter } = useTypedSelector(state => state)

  const [filters, setFilters] = useState({
    visible: false,
    right: "0"
  })

  const [progressState, setProgressState] = useState(false)


  const localizationSelect = (selectSomeItems?:string) => {
    return{
      "allItemsAreSelected": "Все элементы выбраны",
      "clearSearch": "Очистить поиск",
      "clearSelected": "Очистить фильтр",
      "noOptions": "Ничего не найдено",
      "search": "Поиск",
      "selectAll": "Выбрать все",
      "selectAllFiltered": "Выбрать все",
      "selectSomeItems": selectSomeItems ? selectSomeItems : "Выберите варианты",
      "create": "Создать",
    }
  }

  const getCategory = () => {
    let arr:any = []
    let category:any = []
    if (users) {
      users.forEach((item) => {
        JSON.parse(item.category).forEach((item:string,index:number)=>{
          if (item) {
            if (!arr.includes(item.trim())) {
              arr.push(item.trim())
              category.push({value: item.trim().toLowerCase(), label: ucFirst(item.trim())})
            }
          }
        })
      })
    }
    category.sort((a:any, b:any) => a.value.localeCompare(b.value))
    return category
  }
  const getChildCategory = (parentCat:any) => {
    let category:any = []
    let childCategory:any = []
    if (users) {
      users.forEach((e) => {
        if (isJsonString(e.child_category)) {
          const child = JSON.parse(e.child_category.toLowerCase())
          parentCat.forEach(({value}:any) => {
            child.forEach(({parent, name}:any)=>{
              if (parent === value){
                category.push({value: name, label: ucFirst(name)})
              }
            })
          })
          const table:any = {}
          childCategory = category.filter(({value}:any)=>(!table[value] && (table[value] = 1)))
        }
      })
    }
    childCategory.sort((a:any, b:any) => a.value.localeCompare(b.value))
    return childCategory
  }

  return(
    <>
      <div className={styles.filters}>
        <label className={styles.search}>
          <input type='text' placeholder='Поиск'
                 value={filter.search}
                 onChange={(e)=> {
                   filterUser({users: users, value: filter.value, search: e.target.value})
                 }}

          />
          <SearchIcon/>
        </label>
        <div className={styles.trigger}>
          <FiltersIcon onClick={()=>setFilters({visible: true, right: "0"})}/>
        </div>
      </div>
      <div className={styles.overlay}
           onClick={()=>{setFilters({visible: false, right: "-100%"}) }}
           style={filters.visible? {display: "block"} : {display: "none"}}
      />
      <div className={`${styles.wrapper} ${filters.visible? styles.show : ''}`}>
        <SwipeableList>
          <SwipeableListItem
            swipeRight={{
              content: '',
              action: () => progressState ? setFilters({visible: false, right: "-100%"}) : setFilters({visible: true, right: "0"})
            }}
            onSwipeProgress={progress => progress > 30 ? setProgressState(true) : setProgressState(false)}
          >
            <div className={styles.swipeForm}>
              <div className={styles.swipe}></div>
              <div className={styles.form} style={{right: filters.right}}>
                <div className={styles.container}>
                  <div className={styles.header}>
                    <div className={styles.title}>
                      <FiltersIcon/>Фильтр
                    </div>
                    <CloseIcon onClick={()=>setFilters({visible: false, right: "-100%"})}/>
                  </div>

                  <MultiSelect
                    className="select"
                    ClearSelectedIcon={null}
                    options={getCategory()}
                    value={filter.value.category}
                    onChange={(e:any)=> filterUser({users: users, search: filter.search, value: {...filter.value, category: e , child_category: []}}) }
                    labelledBy="Select"
                    hasSelectAll={false}
                    overrideStrings={localizationSelect(  "По сфере бизнеса")}
                  />

                  <MultiSelect
                    className="select"
                    ClearSelectedIcon={null}
                    options={getChildCategory(filter.value.category)}
                    value={filter.value.child_category}
                    onChange={(e:any)=> filterUser({users: users, search: filter.search, value: {...filter.value, child_category: e }}) }
                    labelledBy="Select"
                    hasSelectAll={false}
                    overrideStrings={localizationSelect("Подсфера")}
                  />

                  <input id="partners" type="checkbox"
                         className={styles.checkbox}
                         checked={filter.value.partner}
                         onChange={(e:any)=> filterUser({users: users, search: filter.search, value: {...filter.value, partner: e.target.checked, users:users} })}
                  />
                  <label htmlFor="partners">Партнеры</label>

                  <div className={styles.button} onClick={()=>setFilters({visible: false, right: "-100%"})}>Фильтровать</div>
                  <div className={styles.button}
                       onClick={()=>{
                         filterUser({users: users, search: filter.search, value: {category: [], child_category: [], partner: false } })}
                       }
                  >Очистить фильтр</div>

                </div>
              </div>
            </div>
          </SwipeableListItem>
        </SwipeableList>
      </div>

    </>
  )
}