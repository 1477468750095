import styles from "./EditProfile.module.scss"
import {FC, useEffect, useState} from "react"
import {IUser} from "../../app/store/user/user.type"
import {useUpdateUserMutation} from "../../app/store/user/user.api"
import {ReactComponent as SaveIcon} from "../../assets/icons/save.svg"
import {Avatar} from "../Avatar"
import {Header} from "../Header"
import '../../react-select.scss'
import {isJsonString} from "../../functions/global";
import { useTypedSelector } from "../../app/hooks/useTypedSelector"
import {CategoryInputs} from "../CategoryInputs"
import {Modal} from "../Modal"
import TextareaAutosize from 'react-textarea-autosize'

export const EditProfile:FC<{ user: IUser, users:IUser[]}> = (props) => {
  const user = props.user
  const { categoryChange } = useTypedSelector(state => state)

  useEffect(()=>{
      setForm({...form,
        category: JSON.stringify(categoryChange.map((item: any) => item.category.value)),
        child_category: JSON.stringify(categoryChange.map((item: any) => item.childCategory)).toLowerCase(),
        business: JSON.stringify(categoryChange.map((item: any) => item.business)),
        site: JSON.stringify(categoryChange.map((item: any) => item.site))
      })
  },[categoryChange])

  const defaultValue = {
    id: user.id,
    img_url: user.img_url,
    name: user.name,
    phone: user.phone,
    messenger: user.messenger,
    target: user.target,
    hobby: user.hobby,
    achievements: user.achievements,
    inn: user.inn,
    reffer: user.reffer,
    partner: user.partner,

    category: user.category,
    child_category: user.child_category,
    business: user.business,
    site: user.site
  }

  const [success, setSuccess] = useState({
    visible: false,
    successAlert: '',
  })

  const [form, setForm] = useState(defaultValue)
  const [updateUser] = useUpdateUserMutation()
  const handleSubmit = (e:any) => {
    e.preventDefault()
    updateUser(form).then((res:any) => {
        if (res.error){
          setSuccess({visible: true, successAlert: 'Ошибка'})
          setTimeout(()=>setSuccess({visible: false, successAlert: ''}),1000)
        }else{
          setSuccess({visible: true, successAlert: 'Данные изменены'})
          setTimeout(()=>setSuccess({visible: false, successAlert: ''}),1000)
        }
      })
  }

  const [imgUrl, setImgUrl] = useState(user.img_url)
  return(
    <>
      <Modal
        isVisible={success.visible}
        content={success.successAlert}
        onClose={() => setSuccess({visible: false, successAlert: ''})}
      />
      <Header/>
      <div className={styles.container}>
        <div className={styles.avatar}>
          <Avatar img_url={form.img_url} loader={true}/>
        </div>
        <form onSubmit={handleSubmit}>

          <div className={styles.loader}>
            <input type="text"
                   className={styles.field}
                   placeholder="Введите URL"
                   value={imgUrl}
                   onChange={
                     e => setImgUrl(e.target.value)
                   }
            />
            <div className={styles.button}
                 onClick={
                   () => setForm({...form, img_url: imgUrl})
                 }
            >Загрузить
            </div>
          </div>
          <div className={styles.inform}>(рекомендуемое соотношение сторон 1:1 квадрат)</div>

          <div className={styles.label}>Фамилия Имя</div>
          <TextareaAutosize
            minRows={1}
            maxRows={20}
            className={styles.textarea}
            defaultValue={form.name}
            onChange={
              e => setForm({...form, name: e.target.value})
            }
          />
          {JSON.parse(form.category).length > 0 ?
            JSON.parse(form.category).map((item:string, index:number) => {
              return (
                <CategoryInputs
                  users={props.users}
                  index={index}
                  key={index}
                  category={isJsonString(form.category) ? JSON.parse(form.category)[index] : form.category}
                  childCategory={isJsonString(form.child_category) ? JSON.parse(form.child_category)[index] : user.child_category}
                  business={isJsonString(form.business) ? JSON.parse(form.business)[index] : form.business}
                  site={isJsonString(form.site) ? JSON.parse(form.site)[index] : form.site}
                  length={isJsonString(form.category) ? JSON.parse(form.category).length : 1}
                />
              )
            })
            : <CategoryInputs users={props.users} index={0} key={0} category='' childCategory='' site='' business='' length={1}/>
          }
          <div className={styles.label}>Достижения в бизнесе</div>
          <TextareaAutosize
            minRows={3}
            maxRows={20}
            className={styles.textarea}
            defaultValue={form.achievements}
            onChange={
              e => setForm({...form, achievements: e.target.value})
            }
          />
          <div className={styles.label}>Чем могу быть полезен</div>
          <TextareaAutosize
            minRows={1}
            maxRows={20}
            className={styles.textarea}
            defaultValue={form.target}
            onChange={
              e => setForm({...form, target: e.target.value})
            }
          />
          <div className={styles.label}>Хобби и увлечения</div>
          <TextareaAutosize
            minRows={1}
            maxRows={20}
            className={styles.textarea}
            defaultValue={form.hobby}
            onChange={
              e => setForm({...form, hobby: e.target.value})
            }
          />

          <div className={styles.label}>Кто рекомендовал</div>
          <TextareaAutosize
            minRows={1}
            maxRows={20}
            className={styles.textarea}
            defaultValue={form.reffer}
            onChange={
              e => setForm({...form, reffer: e.target.value})
            }
          />
          <div className={styles.label}>ИНН</div>
          <TextareaAutosize
            minRows={1}
            maxRows={20}
            className={styles.textarea}
            defaultValue={form.inn}
            onChange={
              e => setForm({...form, inn: e.target.value})
            }
          />
          <div className={styles.label}>Номер телефона</div>
          <TextareaAutosize
            minRows={1}
            maxRows={20}
            className={styles.textarea}
            defaultValue={form.phone}
            placeholder="Укажите телефон (данные скрыты)"
            onChange={
              e => setForm({...form, phone: e.target.value})
            }
          />
          <div className={styles.label}>Профиль в соц. сети</div>
          <TextareaAutosize
            minRows={1}
            maxRows={20}
            className={styles.textarea}
            defaultValue={form.messenger}
            placeholder="Укажите ссылку (данные скрыты)"
            onChange={
              e => setForm({...form, messenger: e.target.value})
            }
          />
          <input id="partners" type="checkbox"
                 checked={form.partner?true:false}
                 className={styles.checkbox}
                 onChange={(e:any)=> {
                     setForm({...form, partner: e.target.checked})
                   }
                 }
          />
          <label htmlFor="partners">Партнер</label>

          <button className={styles.button}
               style={{marginBottom: '32px'}}
               type='submit'
          >
            <SaveIcon/>
            Обновить профиль
          </button>
        </form>
      </div>
    </>
  )
}