import styles from './Faq.module.scss'
import {ReactComponent as HelpIcon} from "../../assets/icons/help.svg"
import {useState} from "react"

export const Faq = () => {
  const [info, setInfo] = useState(false)

  return(
    <>
      {info ? <div className={styles.overlay} onClick={()=>setInfo(false)}/> : ''}
      <div className={styles.faq}>
        <div className={styles.title}>Инструкция как пользоваться поиском</div>
        <div className={styles.icon} onClick={()=>setInfo(info ? false : true)}>
          <HelpIcon/>
        </div>
        {info
          ? <div className={styles.card}>
            <div className={styles.content}>
              В поиске можно искать как по ключевым словам,
              так и по сфере бизнеса. Сверху вы увидите анкеты с самым высоким рейтингом,
              а также анкеты наших партнеров
            </div>
          </div>
          : ''}
      </div>
    </>
  )
}