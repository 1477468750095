import styles from './Header.module.scss'
import {ReactComponent as Logo} from '../../assets/icons/logo.svg'

export const Header = () => {
  return(
    <>
      <header className={styles.header}>
        <div className={styles.logo}>
          <Logo/>
        </div>
      </header>
    </>
  )
}