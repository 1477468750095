import {Header} from "./components/Header"
import {Filters} from "./components/Filters"
import {UserList} from "./components/UserList"
import {BrowserRouter, Routes, Route, useLocation} from "react-router-dom"
import {Faq} from "./components/Faq"
import {useGetUsersQuery} from "./app/store/user/user.api"
import {isAdmin, TG, TGUser} from "./functions/global"
import {UserProfile} from "./components/UserProfile"
import React, {FC, useEffect, useState} from "react"
import {Modal} from "./components/Modal"
import {Slider} from "./components/Slider"
import {ThreeDots} from "react-loader-spinner"
import {LocationProvider} from "./components/LocationProvider"
import Cookies from "js-cookie"
import {IUser} from "./app/store/user/user.type"
import { AdminChange } from "./components/AdminChange"
import { useActions } from "./app/hooks/useActions"

export const App = () => {
  const {data, isLoading, error} = useGetUsersQuery()
  const [message, setMessage] = useState({
    text: '',
    visible: false
  })

  useEffect(()=>{
    if (TGUser === undefined) {
      // setMessage({text:'У вас нет статуса членства', visible: true})
    }
  }, [])


  if (message.visible){
    return (
      <Modal
        isVisible={message.visible}
        content={message.text}
      />
    )
  }else{
    TG.expand()
  }

  const Main:FC<{users:IUser[]}> = (props) => {

    const { pathname } = useLocation()
    const { categoryDrop } = useActions()
    useEffect(()=>{
      const scrollState = Cookies.get("scrollState")
      if (scrollState){
        console.log(scrollState)
        window.scrollTo(0, Number(scrollState))
      }
      categoryDrop()
    }, [pathname])

    return(
      <>
        <Header/>
        <Slider/>
        <Faq/>
        <Filters users={props.users}/>
        {/*<ApiTests/>*/}
        {
          isLoading
          ? <ThreeDots
              height="50"
              width="50"
              radius="9"
              color="#E0AA44"
              ariaLabel="three-dots-loading"
              wrapperClass="loader"
              visible={true}
            />
          : error ? `error: ${error}`
          : props.users ? <UserList users={props.users}/>
          : 'Не известная ошибка'
        }
      </>
    )
  }
  return (
    <BrowserRouter>
      <LocationProvider>
        <div className="App">
          {isAdmin() ? <AdminChange/> : ''}
          <Routes>
            <Route index element={<Main users={data!}/>}/>
            <Route path=":id" element={<UserProfile users={data!}/>} />
            <Route path="*" element={<h2>Ресурс не найден</h2>} />
          </Routes>
        </div>
      </LocationProvider>
    </BrowserRouter>
  )
}
