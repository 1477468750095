import {useDispatch} from "react-redux";
import {bindActionCreators} from "@reduxjs/toolkit";
import {filterActions} from '../store/filter/filter.slice'
import {adminChangeActions} from "../store/form/adminChange.slice";
import { categoryChangeActions } from "../store/form/categoryChange.slice";

const allActions = {
  ...filterActions, ...adminChangeActions, ...categoryChangeActions,
}

export const useActions = () => {
  const dispatch = useDispatch()

  return bindActionCreators(allActions, dispatch)
}