import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import {IUser} from "./user.type"
import {apiDomain} from "../../../functions/global"

export const api = createApi({
  reducerPath: 'api',
  tagTypes: ['User'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiDomain()
  }),
  endpoints: builder => ({
    getUsers: builder.query<IUser[], void>({
      query: () => 'users',
      providesTags: () => [{
        type: 'User',
      }],
    }),
    createUser: builder.mutation({
      query: (user) => ({
        body: user,
        url: `create_user`,
        method: 'POST',
      }),
      invalidatesTags: () => [{
        type: 'User',
      }],
    }),
    updateUser: builder.mutation({
      query: (data) => ({
        body: data,
        url: `update_user`,
        method: 'POST',
      }),
      invalidatesTags: () => [{
        type: 'User',
      }],
    }),
    removeUser: builder.mutation({
      query: (id) => ({
        body: id,
        url: `delete_user`,
        method: 'POST',
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        await queryFulfilled
        setTimeout(() => {
          dispatch(api.util.invalidateTags(['User']))
        }, 1000)
      },
    }),
  }),
})

export const { useGetUsersQuery, useCreateUserMutation, useUpdateUserMutation, useRemoveUserMutation } = api