import {ReactComponent as StarIcon} from '../../assets/icons/star.svg'
import styles from './Rating.module.scss'
import {FC} from "react"
export const Rating:FC<{rating: any}> = (props) => {

  function isJson(str:any) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }
  let rating = isJson(props.rating) ? props.rating : JSON.stringify({rating: '', count: '', points: 0})
  rating = Number(JSON.parse(rating)['points']).toFixed(1)


  return(
    <div className={styles.wrapper}>
      <div className={rating > 0 ? styles.rating : styles.notRating }>
          <div>
            {Array.from({ length: rating > 0 ? rating : 5 }, (_, index) => <StarIcon key={`_${index}`} /> )}
          </div>
          <div className={styles.number}>{rating > 0 ? rating : 'без оценки'}</div>
        </div>
    </div>
  )
}