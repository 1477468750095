import React, {FC, useEffect, useState} from "react"
import {Link, useLocation, useParams} from "react-router-dom"
import {NotFound} from "../NotFound"
import styles from "./UserProfie.module.scss"
import {ReactComponent as BusinessIcon} from '../../assets/icons/business.svg'
import {ReactComponent as CompanyIcon} from '../../assets/icons/company.svg'
import {ReactComponent as LinkIcon} from '../../assets/icons/link.svg'
import {ReactComponent as AboutIcon} from '../../assets/icons/about.svg'
import {ReactComponent as PinIcon} from '../../assets/icons/pin.svg'
import {ReactComponent as Star4Icon} from '../../assets/icons/star4.svg'
import {IUser} from "../../app/store/user/user.type"
import {Avatar} from "../Avatar"
import {Actions} from "../Actions";
import {EditProfile} from "../EditProfile";
import {Rating} from "../Rating";
import {ThreeDots} from "react-loader-spinner";
import {useTypedSelector} from "../../app/hooks/useTypedSelector";
import {isAdmin, isJsonString, isValidUrl, ucFirst} from "../../functions/global";
import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow.svg"

export const UserProfile:FC<{users: IUser[]}>= (props) => {

  const users = props.users
  const params = useParams()
  const { adminChange } = useTypedSelector(state => state)
  const { pathname } = useLocation()
  useEffect(()=>{
    window.scrollTo(0, 0)
  }, [pathname])

  if (!users || users.length === 0) {
    return (
      <ThreeDots
        height="calc(100vh - 200px)"
        width="50"
        radius="9"
        color="#E0AA44"
        ariaLabel="three-dots-loading"
        wrapperClass="loader"
        visible={true}
      />)
  }

  const user = users.filter((user: IUser) => {
    return user.id === Number(params.id)
  })[0]
  if (!user) return <NotFound/>

  return(
    <>
      <div className={styles.back}>
        <Link className={styles.backLink} to='/'>
          <ArrowIcon/>Вернуться
        </Link>
      </div>
    {
      adminChange
      ? <EditProfile user={user} users={users}/>
      : <>
        <div className={styles.container}>
          <div className={styles.avatar}>
            <Avatar img_url={user.img_url}/>
          </div>
          <div className={styles.rating}>
            <Rating rating={user.rating}/>
          </div>
          <div className={styles.name}>{user.name}</div>
          <div className={styles.info}>
            <div className={styles.wrapper}>
            {
              JSON.parse(user.category).map((item: string, index:number) => {
                const c = user.child_category
                const childCategory = isJsonString(c) ? JSON.parse(c)[index].name : ''
                const business = JSON.parse(user.business)[index]
                const site = JSON.parse(user.site)[index]
                return (
                  item || childCategory || business || site?
                  <div className={styles.items} key={index}>
                    {item ? <div className={`${styles.item} ${styles.category}`}><BusinessIcon/>{ucFirst(item)}</div> : ''}
                    {childCategory ? <div className={`${styles.item} ${styles.child}`}><BusinessIcon/>{ucFirst(childCategory)}</div> : ''}
                    {business ? <div className={styles.item}><CompanyIcon/>{ucFirst(business)}</div> : ''}

                    {site ? <a href={isValidUrl(site) ? site : 'http://' + site} target="_blank"
                         className={`${styles.item} ${styles.link}`}><LinkIcon/>{site}</a> : ''}
                  </div>
                  : ''
                  )
              })
            }
            </div>
            {user.achievements ?<div className={styles.title}><AboutIcon/>Достижения в бизнесе</div> : ''}
            {user.achievements ? <div>{user.achievements}</div> : ''}

            {user.target ? <div className={styles.title}><PinIcon/>Чем могу быть полезен</div> : ''}
            {user.target ? <div>{user.target}</div> : ''}

            {user.hobby ? <div className={styles.title}><Star4Icon/>Хобби и увлечения</div> : ''}
            {user.hobby ? <div>{user.hobby}</div> : ''}
          </div>
        </div>
        <Actions user={user}/>
      </>
    }
    </>
  )
}