import slide1 from '../../assets/img/1.jpeg'
import 'swiper/css';
import styles from './Slider.module.scss'
import {Swiper, SwiperSlide} from "swiper/react";

export const Slider = () => {
  return (
    <Swiper
      className={styles.swiper}
      spaceBetween={50}
      slidesPerView={1}
      // onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper: any) => console.log(swiper)}
    >
      <SwiperSlide className={styles.slide}><a href='#'><img src={slide1} /></a></SwiperSlide>
    </Swiper>
  )
}