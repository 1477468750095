import styles from "./Actions.module.scss"
import {ReactComponent as GetContactIcon} from "../../assets/icons/getcontact.svg"
import {ReactComponent as SuccessIcon} from "../../assets/icons/success.svg"
import {FC, useState} from "react"
import {IUser} from "../../app/store/user/user.type"
import {Modal} from "../Modal"
import axios from "axios"
import {apiDomain, TG, TGUser} from "../../functions/global"
import {useActions} from "../../app/hooks/useActions"
import {ThreeDots} from "react-loader-spinner"

export const Actions:FC<{user:IUser}> = (props) => {
  const user = props.user

  const [message, setMessage] = useState({
    success: false,
    text: '',
    visible: false,
  })

  //Отправка сообщения
  const sendMessage = (user_id:number) => {
    console.log(TGUser)
    setMessage({success: false, text: '', visible: true})
    if (TGUser) {
      const data = {'id': user_id, 'tg': TGUser.id.toString()}
      axios.post(apiDomain() + '/send_message', data)
        .then(function (response) {
          setMessage({success: true, text: 'Контакт отправлен вам в личные сообщения', visible: true})
          // setTimeout(()=>TG.close(),3000)
        })
        .catch(function (error) {
          setMessage({success: false, text: 'Произошла ошибка, попробуйте снова или обратитесь в поддержку', visible: true})
        })
    }else{
      setMessage({success: false, text: 'Ваш телеграм id не найден', visible: true})
    }
  }

  return(
    <div className={styles.actions}>
        <button className={styles.button} onClick={()=> {sendMessage(user.id)} }>
          <GetContactIcon/>Получить контакт
        </button>
        <Modal
          isVisible={message.visible}
          content={<div className={styles.message}>{message.success ? <SuccessIcon/> : ''}
            {message.text
              ? message.text
              : <ThreeDots
                height="50"
                width="50"
                radius="9"
                color="#E0AA44"
                ariaLabel="three-dots-loading"
                wrapperClass="loader"
                visible={true}
                />
            }</div>}
          onClose={() => setMessage({success: false, text: '', visible: false})}
        />
    </div>
  )
}