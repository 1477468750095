import Cookies from 'js-cookie'
import { useState } from 'react'
import { useActions } from '../../app/hooks/useActions'
import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg'
import styles from "./AdminChange.module.scss"

export const AdminChange = () => {
  const { adminChange, categoryDrop } = useActions()
  const [admin, setAdmin] = useState(false)

  const change = (isAdmin:boolean) => {
    setAdmin(isAdmin)
    adminChange(isAdmin)
  }

  const setCcrollState = () => {
    if (window.location.pathname === '/') {
      Cookies.set("scrollState", window.scrollY.toString(), {
        expires: new Date(new Date().getTime() + 10 * 60 * 1000), // 10 min
      })
    }
  }
  return(
    <div className={`${styles.button} ${admin ? styles.active : ''}`}
         onClick={()=> {
           setCcrollState()
           change(admin ? false : true)
           categoryDrop()
         }}><EditIcon/>
    </div>
  )
}