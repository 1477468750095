import {adminIds} from './adminDB'
export const TG = window.Telegram.WebApp
export const TGUser = TG.initDataUnsafe.user
export const apiDomain = () => {
  if (window.location.hostname === 'localhost'){
    return 'http://localhost:5002/api'
  }else{
    return '/api'
  }
}

export const isJsonString = (str:string) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const isValidUrl = (str:string) => {
  try {
    new URL(str)
    return true;
  } catch (err) {
    return false
  }
}

export const isAdmin = () => {
  if (window.location.hostname === 'localhost'){
    return true
  }else {
    if (TGUser !== undefined) {
      return adminIds.filter(e => e === TGUser.id.toString()).length > 0
    } else {
      return false
    }
  }
}

export const ucFirst = (str:string) => {
  if (!str) return str
  return str[0].toUpperCase() + str.slice(1)
}