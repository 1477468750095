import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: boolean = false

export const adminChangeSlice = createSlice({
  name: 'adminChange',
  initialState,
  reducers: {
    adminChange: (state, action: PayloadAction<boolean>) => {
      return action.payload
    },
  },
})

export const adminChange = adminChangeSlice.reducer
export const adminChangeActions = adminChangeSlice.actions