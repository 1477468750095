import styles from './User.module.scss'
import {Link, useLocation} from "react-router-dom"
import {FC, useState} from "react"
import {IUser} from "../../app/store/user/user.type"
import {useRemoveUserMutation} from "../../app/store/user/user.api"
import {Rating} from "../Rating"
import {ReactComponent as BusinessIcon} from "../../assets/icons/business.svg"
import {ReactComponent as PartnerIcon} from "../../assets/icons/partner.svg"
import {Avatar} from "../Avatar"
import Cookies from "js-cookie";
import { Modal } from '../Modal'
import { ucFirst } from '../../functions/global'
import { useTypedSelector } from '../../app/hooks/useTypedSelector'

export const  User: FC<{ user: IUser }> = (props) => {
  const user = props.user
  const partner = user.partner === 1
  const [remove, setRemove] = useState({
    visible: false,
    removeAlert: '',
  })
  const { adminChange } = useTypedSelector(state => state)
  const [removeUser] = useRemoveUserMutation()
  const { pathname } = useLocation()
  const scrollState = () => {

    Cookies.set("scrollState", window.scrollY.toString(), {
      expires: new Date(new Date().getTime() + 10 * 60 * 1000), // 10 min
    })
  }

  const RemoveAlert = () => {
    return(
      <>
        {remove.removeAlert}
      </>
    )
  }

  const RemoveConfirm = () => {
    return(
      <div className={styles.confirm}>
        <div className={styles.text}>
          Вы действительно хотите удалить анкету?
        </div>
        <div className={styles.buttons}>
          <div className={styles.button}
               onClick={()=> {
                 removeUser({id: user.id}).then((res:any) => {
                   if (res.error){
                     setRemove({visible: true, removeAlert: 'Ошибка удаления'})
                   }else{
                     setRemove({visible: true, removeAlert: 'Пользователь удален'})
                   }
                 })
               }}
          >Удалить</div>
          <div className={styles.button} onClick={()=>setRemove({visible: false, removeAlert: ''})}>Отмена</div>
        </div>
      </div>
    )
  }
  return(
    <div className={styles.card}>
      <Modal
        isVisible={remove.visible}
        content={remove.removeAlert ? <RemoveAlert/> : <RemoveConfirm/>}
        onClose={() => setRemove({visible: false, removeAlert: ''})}
      />
      {adminChange ?
        <div className={styles.close} onClick={() => setRemove({visible: true, removeAlert: ''})}>+</div> : ''}
      <Link to={`${pathname}${user.id}`} onClick={() => scrollState()}>
        <div className={styles.content}>
          {partner ? <div className={styles.label}><PartnerIcon/>Партнер</div> : ''}
          <div className={styles.info}>
            <Avatar img_url={user.img_url}/>
            <Rating rating={user.rating}/>
            <div className={styles.name}>{user.name}</div>
            {
              JSON.parse(user.category).map((item: string, index:number) => {
                return (item && item.length > 0 && JSON.parse(user.category).indexOf(item) === index) ?
                <div key={index} className={styles.item}><BusinessIcon/>{ucFirst(item)}</div> : ''
              })
            }
          </div>
          <div className={styles.button}>К анкете</div>
        </div>
      </Link>
    </div>
  )
}