import styles from "./Avatar.module.scss"
import {ReactComponent as NoAvatarIcon} from "../../assets/icons/noavatar.svg"
import {ReactComponent as InfoIcon} from "../../assets/icons/info.svg"
import {FC, useEffect, useRef, useState} from "react"

export const Avatar:FC<{img_url:string, loader?:boolean}> = (props) => {
  const img_url = props.img_url
  const loader = props.loader
  const [errorAvatar, setErrorAvatar] = useState(false)
  const ref = useRef<HTMLHeadingElement>(null)
  const [avatarStyle, setAvtarStyle] = useState({height: 'auto'})
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  })

  useEffect(() => {
    setAvtarStyle({height: ref.current ? `${ref.current.offsetWidth.toString()}px` : 'auto'})
  }, [windowWidth, errorAvatar])
  const ErrorContent = () => {
   return(
     <>{loader
     ? <div className={styles.error_avatar}><InfoIcon/>Не удалось загрузить фото, попробуйте другое.</div>
     : <div className={styles.not_avatar}><NoAvatarIcon/></div>}
     </>)
  }

  return(
    <div className={styles.avatar} ref={ref} style={avatarStyle}>
      {
        img_url
          ? <>
            {!errorAvatar ? <ErrorContent/> : ''}
              <img
               src={img_url} alt='avatar'
               onError={()=> setErrorAvatar(false)}
               onLoad={()=> setErrorAvatar(true)}
              />
            </>
          : <div className={styles.not_avatar}><NoAvatarIcon/></div>
      }
    </div>
  )
}